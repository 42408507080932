<template>
  <div class="open-card">
    <div class="open-card-top">
      <span>会员卡</span>
    </div>
    <div class="open-card-banner">
      <img src="@static/home/huiyuan.png" alt="">
    </div>
    <div class="open-card-go">
      <img src="@static/home/jihuo.png" alt="">
    </div>
    <div class="open-card-main">
      <div class="open-card-title">必填信息</div>
      <div class="open-card-text">
        <label for="phone">手机号</label>
        <input v-model="phone" autofocus id="phone" type="tel" >
      </div>
      <div class="open-card-text">
        <label for="phone">验证码</label>
        <input v-model="check_code" autofocus id="phone" type="tel" >
        <div class="get-code">
          <Button color="rgb(237, 102, 77)" :disabled="btn_status" @click="getCode" block>{{code_text}}</Button>
        </div>
      </div>
      <div class="open-card-agree">
        <Checkbox checked-color="#888888" v-model="status" aria-checked="true" shape="square" icon-size="14px">
          我已阅读并同意<span class="open-card-info">会员卡信息使用说明</span>
        </Checkbox>
      </div>
      <div class="go-btn">
        <Button 
          type="primary" 
          block
          v-if="type"
          @click="addVipUser"
          color="linear-gradient(90deg,rgba(237,102,77,1) 0%,rgba(245,149,122,1) 100%)">
          立即登录
        </Button>
        <Button 
          type="primary" 
          block
          v-else
          @click="editPhone"
          color="linear-gradient(90deg,rgba(237,102,77,1) 0%,rgba(245,149,122,1) 100%)">
          修改号码
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox, Button } from 'vant';
import { checkMobile } from "@/utils/validate";
import UserInfo from '@/api/user';
import { getUrlArgObject } from "@/utils/tools";
export default {
  components: {
    Checkbox, Button
  },
  data() {
    return {
      phone: "",
      check_code: "",
      status: true,
      code_text: "获取验证码",
      timer: null,
      btn_status: false,
      count: "",
      type: true,
      reco_code: ""
    }
  },
  created() {
    let url = getUrlArgObject();
    if (url.hasOwnProperty('reco_code')) this.reco_code = url.reco_code;
    if (this.$route.query.type == 101) {
      this.type = false;
    }
  },
  methods: {
    getCode() {
      const TIME_COUNT = 60;
      if (!this.btn_status) {
        if (checkMobile(this.phone) == "手机号码输入不规范") {
          this.$toast("手机号码格式不正确");
        } else {
          if (!this.timer) {
            this.count = TIME_COUNT;
            UserInfo.getPhoneCode(this.phone).then(res => {
              this.$toast(res.msg);
            })
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                this.code_text = this.count;
                this.btn_status = true;
              } else {
                this.btn_status = false;
                this.timer = null;
                this.code_text = "获取验证码";
              }
            }, 1000);
          }
        }
      }
    },
    
    // 修改绑定手机号
    async editPhone() {
      try {
        if (checkMobile(this.phone) != "") return this.$toast("手机号码输入不规范");
        if (this.check_code == "") return this.$toast("请输入短信验证码");
        if (!this.status) return this.$toast("请您同意协议后提交");
        const res = await UserInfo.changeBindPhone(this.phone, this.check_code);
        if (res.code == 200) {
          this.$toast(res.msg);
          sessionStorage.setItem('access_token', res.data.app_token);
          this.$router.push('/vip');
        } else {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    // 会员注册
    async addVipUser() {
      try {
        if (checkMobile(this.phone) != "") return this.$toast("手机号码输入不规范");
        // if (this.check_code == "") return this.$toast("请输入短信验证码");
        if (!this.status) return this.$toast("请您同意协议后提交");
        const res = await UserInfo.vipRegister(this.phone, this.check_code, this.reco_code);
        if (res.code == 200) {
          this.$toast(res.msg);
          localStorage.setItem('access_token', res.data.app_token || res.data.wx_token);
          sessionStorage.setItem("access_token", res.data.app_token || res.data.wx_token);
          sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          setTimeout(() => {
						let qrcode_id = sessionStorage.getItem("qrcode_id");
            // 公众号入口进入
            if (this.reco_code) return this.$router.push("/invitation?text_type=2");
						if (qrcode_id == "undefined" || qrcode_id == null || qrcode_id == "null") {
							this.$router.push("/vip");
						} else {
							this.$router.push("/cardpay?qrcode_id=" + qrcode_id);
						}
					});
        } else if (res.code == 500) {
          sessionStorage.clear();
          window.location.href = "https://www.58zltc.com/vip?appid=" + sessionStorage.getItem("appid")
        } else if (res.code == 600) {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    }
  },
  beforeDestroy() {
		clearInterval(this.timer);
	}
}
</script>
<style lang="scss" scoped>
.open-card {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 62px 12px 0;
  background:rgba(248,248,248,1);
  .open-card-top {
    width: 100%;
    height: 45px;
    position: absolute;
    left: 0;
    top: 0;
    font-family:PingFangSC-Medium,PingFang SC;;
    line-height: 45px;
    background: #fff;
    text-align: center;
    font-size: 18px;
    font-weight:500;
    color:rgba(51,51,51,1);
  }
  .open-card-banner {
    width: 98%;
    height: 146px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .open-card-go {
    width: 98%;
    height: 58px;
    margin: 12px auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .open-card-main {
    width: 98%;
    height: 230px;
    margin: 0 auto;
    background:rgba(255,255,255,1);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .open-card-title, .open-card-text {
      width: 100%;
      height: 50px;
      position: relative;
      font-size: 13px;
      color: #999999;
      display: flex;
      font-weight:500;
      padding-left: 20px;
      align-items: center;
      border-bottom: 1px solid #EEEEEE;
      .get-code {
        position: absolute;
        width: 88px;
        height: 30px;
        right: 10px;
        Button {
          width: 100%;
          height: 100%;
          font-size: 10px;
          line-height: 30px;
        }
      }
    }
    .open-card-text {
      color: #333333;
      input {
        display: inline-block;
        border: none;
        padding-left: 30px;
      }
    }
    .open-card-agree {
      width: 98%;
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 0 18px;
      letter-spacing: 2px;
      .open-card-info {
        color: #ED664D;
      }
    }
    .go-btn {
      width: 92%;
      height: 40px;
      margin: 12px auto;
      background:rgba(255,255,255,1);
      box-shadow:0px 0px 4px 0px rgba(0,0,0,0.04);
      border-radius:5px;
    }
  }
}
</style>
